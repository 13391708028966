<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content">
      <div class="top-content-text">
        <h1 class="top-content-text-title">Таргетированная <br>реклама</h1>
        <div class="top-content-text-plates">
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/users-alt.svg"
              alt="">
            <div class="top-content-text-plates-plate-text">
              <h3 class="top-content-text-plates-plate-text-title">96%</h3>
              <p class="top-content-text-plates-plate-text-description">пользователей
                рунета — охват
                аудитории
                проектов</p>
            </div>
          </div>
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/megaphone.svg"
              alt="">
            <div class="top-content-text-plates-plate-text">
              <h3 class="top-content-text-plates-plate-text-title">400</h3>
              <p class="top-content-text-plates-plate-text-description">таргетингов для
                выбора целевой
                аудитории и
                настройки
                рекламы</p>
            </div>
          </div>
          <div class="top-content-text-plates-plate">
            <img
              class="top-content-text-plates-plate-image"
              src="@/assets/images/svg/money.svg"
              alt="">
            <div class="top-content-text-plates-plate-text">
              <h3 class="top-content-text-plates-plate-text-title">600р</h3>
              <p class="top-content-text-plates-plate-text-description">составляет
                стартовый
                бюджет
                рекламной
                кампании</p>
            </div>
          </div>
        </div>
        <div class="top-content-left">
          <img
            class="top-content-left-image"
            src="@/assets/images/Artboard-31.webp"
            alt="">
        </div>
        <p class="top-content-text-description">
          Вы запускаете новую акцию, распродажу или новую <br>
          систему скидок, и вам нужно, чтобы об этом узнали <br>
          тысячи новых людей? Хотите повысить уровень <br>
          продаж? Вам поможет таргетированная реклама в <br>
          социальных сетях!
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top {
  margin-top: get-vw(400px);
  @media (orientation: landscape){
    margin-top: get-vw(200px);
  }
  &-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    @media (orientation: landscape){
      width: 65%;
    }
    &-left {
      width: 100%;
      margin-top: get-vw(-100px);
      @media (orientation: landscape){
        display: none;
        margin-top: 0;
      }
      &-image {
        margin-left: get-vw(-55px);
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: get-vw(145px);
        line-height: get-vw(175px);
        color: #000000;
        padding-left: get-vw(15px);
        @media (orientation: landscape){
          font-size: get-vw(110px);
          line-height: get-vw(130px);
        }
      }
      &-plates {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: get-vw(85px);
        &-plate {
          display: flex;
          flex-direction: row;
          width: 80%;
          align-items: center;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-top: get-vw(300px);
          @media (orientation: landscape){
            margin-top: get-vw(100px);
          }
          &-image {
            width: get-vw(350px);
            height: get-vw(350px);
            @media (orientation: landscape){
              width: get-vw(200px);
              height: get-vw(200px);
            }
          }
          &-text {
            display: flex;
            flex-direction: column;
            margin-left: get-vw(150px);
            &-title {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 700;
              font-size: get-vw(120px);
              line-height: 85.52%;
              text-align: center;
              color: #000000;
              @media (orientation: landscape){
                font-size: get-vw(70px);
              }
            }
            &-description {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: get-vw(75px);
              line-height: 140%;
              text-align: center;
              color: #1C1C1C;
              margin-top: get-vw(65px);
              @media (orientation: landscape){
                font-size: get-vw(40px);
                margin-top: get-vw(40px);
              }
            }
          }
        }
      }

      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(65px);
        line-height: get-vw(90px);
        color: #000000;
        margin-top: get-vw(-150px);
        white-space: nowrap;
        @media (orientation: landscape){
          font-size: get-vw(40px);
          line-height: get-vw(55px);
          margin-top: get-vw(150px);
        }
      }
    }
  }
}
</style>
